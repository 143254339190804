import { updateClient, matrixClient } from "@/core/services/matrix";

const matrixAuthStorage = localStorage.getItem("matrixAuth");

const state = () => ({
  roomsByAlias: {},
  matrixAuth: matrixAuthStorage ? JSON.parse(matrixAuthStorage) : null,
});

const getters = {
  roomsByAlias: (s) => s.roomsByAlias,
};

const actions = {
  getRooms({ commit }) {
    const rooms = matrixClient.getRooms();
    const aliasAndRoom = rooms.map((r) => [r.getCanonicalAlias(), r]);
    const roomsByAlias = Object.fromEntries(aliasAndRoom);
    commit("SET_ROOMS_BY_ALIAS", roomsByAlias);
  },
  autoJoin({ state, dispatch }) {
    matrixClient.on("RoomMember.membership", function(event, member) {
      const matrixUserId = state.matrixAuth.user_id; // todo change to goose url
      if (member.membership === "invite" && member.userId === matrixUserId) {
        matrixClient.joinRoom(member.roomId).then(function() {
          dispatch("getRooms");
        });
      }
    });
  },
  matrixSync({ dispatch }) {
    matrixClient.once("sync", (matrixState) => {
      if (matrixState === "PREPARED") {
        dispatch("getRooms");
        dispatch("autoJoin");
      }
    });
  },
  // eslint-disable-next-line no-unused-vars
  async matrixAuth({ dispatch, state, commit }, data) {
    // todo: get access token from strapi
    // TODO either create store for chat or just call when chat componenets are opened.
    try {
      if (state.matrixAuth) {
        updateClient(state.matrixAuth);
        matrixClient.startClient();

        dispatch("matrixSync");
      } else {
        const matrixUserId = `@goose${data.id}:${process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN}`; // todo change to goose url
        const password = Buffer.from(matrixUserId).toString("base64");
        // console.log(matrixUserId);
        // console.log(password);

        const matrixAuth = await matrixClient.login("m.login.password", {
          user: matrixUserId,
          password: password,
        });
        commit("SET_MATRIX_AUTH", matrixAuth);
      }
      matrixClient.startClient();
      dispatch("matrixSync");
    } catch (error) {
      // console.log(error);
    }
  },
};

const mutations = {
  SET_ROOMS_BY_ALIAS(state, value) {
    state.roomsByAlias = value;
  },
  SET_MATRIX_AUTH(state, value) {
    localStorage.setItem("matrixAuth", JSON.stringify(value));
    state.matrixAuth = value;
  },
  REMOVE_MATRIX_AUTH(state) {
    state.matrixAuth = null;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
