import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";

import acl from "./acl";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import "./plugins/vue-world-map";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";

import "bootstrap/dist/css/bootstrap.min.css";

import ApiService from "@/core/services/api.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import VueApollo from "vue-apollo";

// API service init
ApiService.init();

// Remove this to disable mock API

router.beforeEach(async (to, from, next) => {
  // Ensure we checked auth before each page load.
  await store.dispatch(VERIFY_AUTH);
  next();

  // reset config to initial state

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

// strapi graphql client - main
// const apolloClient = new ApolloClient({
//   uri: "http://18.217.184.145:1337/graphql"
// })
import apolloClient from "./vue-apollo-strapi";
Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  clients: {
    apolloClient,
  },
  defaultClient: apolloClient,
});
Vue.config.productionTip = false;

import CKEditor from "@ckeditor/ckeditor5-vue";

Vue.use(CKEditor);
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import { BootstrapVue } from "bootstrap-vue";
import ElementUI from "element-ui";

Vue.use(VueMaterial);

Vue.use(ElementUI);
Vue.use(BootstrapVue);

new Vue({
  router,
  acl,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
  apolloProvider,
}).$mount("#app");
