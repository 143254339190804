<template>




















  <div class="container">

<div>





      <div id="kt_subheader" class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid">
                            <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center flex-wrap mr-2">
                                    <!--begin::Page Title-->
                                    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Supplier Detail Page</h5>
                                    <!--end::Page Title-->
                                    <!--begin::Action-->
                                    <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>

                                    <span class="text-muted font-weight-bold mr-4"> Jacks Crab and Bike Manufacturing</span>
 <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>



                                    <!--end::Action-->
                                </div>
                                <!--end::Info-->

                            </div>






              </div>


            <div class="row">





<v-img

       s :src="api_url + company.image.url"  height="200px" class="background-cover" alt="..."> </v-img>

                                <div class="media pt-5">
                                </div>

                                <!--begin::Card-->
                                    <div class="card card-custom gutter-b">

                                    <div class="card-body">
                                        <!--begin::Details-->
                                        <div class="d-flex mb-9">
                                            <!--begin: Pic-->

                                            <!--end::Pic-->
                                            <!--begin::Info-->
                                            <div class="flex-grow-1">
                                                <!--begin::Title-->
                                                <div class="d-flex justify-content-between flex-wrap mt-1">
                                                    <div class="d-flex mr-3">
                                                        <a href="#" class="text-dark-75 text-hover-primary font-size-h2 font-weight-bold mr-3">
                                                            {{ company.company_name }}
</a>

                                                    </div>
                                                    <div class="my-lg-0 my-3">
                                                        <a href="#" class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3">Contact</a>
                                                        <a href="#" class="btn btn-sm btn-info font-weight-bolder text-uppercase">Apply</a>
                                                    </div>
                                                </div>
                                                <!--end::Title-->
                                                <!--begin::Content-->
                                                <div class="d-flex flex-wrap justify-content-between mt-1">

                                                    <div class="d-flex flex-column flex-grow-1 pr-8">
                                                        <div class="d-flex flex-wrap mb-4">
                                                            <a href="#" class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                            <i class="flaticon2-new-email mr-2 font-size-lg"></i>{{ company.contactemail }}</a>
                                                            <a href="#" class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                            <i class="flaticon2-calendar-3 mr-2 font-size-lg"></i>Verified Vendor</a>
                                                            <a href="#" class="text-dark-50 text-hover-primary font-weight-bold">
                                                            <i class="flaticon2-placeholder mr-2 font-size-lg"></i>{{ company.city }}, {{ company.state }}</a>
                                                        </div>
                                                        </div>

                                                    <div class="row">
                                                         <div class="col-lg-8">


  {{ company.CompanyDescription }}

                                              </div>
                                                        <div class="col-lg-3">
                                                        <img class="img-fluid  mr-2" max-width="350px" src="media/bike.jpg" alt="image">
                                                        </div>
                                                    </div>






                                                </div>
                                                <!--end::Content-->
                                            </div>
                                            <!--end::Info-->
                                        </div>
                                        <!--end::Details-->
                                        <div class="separator separator-solid"></div>
                                        <!--begin::Items-->
                                        <div class="d-flex align-items-center flex-wrap mt-8">
                                            <!--begin::Item-->
                                            <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                                                <span class="mr-4">
                                                    <i class="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>
                                                </span>
                                                <div class="d-flex flex-column text-dark-75">
                                                    <span class="font-weight-bolder font-size-lg">Average Margin</span>
                                                    <span class="font-weight-bolder font-size-h5">
                                                    40%</span>
                                                </div>
                                            </div>
                                            <!--end::Item-->

                           <!--begin::Item-->
                                            <div class="d-flex align-items-center  flex-lg-fill mr-5 mb-2">
                                                <span class="mr-4">
                                                    <i class="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>
                                                </span>
                                                <div class="d-flex flex-column text-dark-75">
                                                    <span class="font-weight-bolder font-size-lg">MDF - Marketing</span>
                                                    <span class="font-weight-bolder font-size-h5">
                                                    2% of Gross</span>
                                                </div>
                                            </div>
                                            <!--end::Item-->
                                            <!--begin::Item-->
                                            <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                                                <span class="mr-4">
                                                    <i class="flaticon-confetti display-4 text-muted font-weight-bold"></i>
                                                </span>
                                                <div class="d-flex flex-column text-dark-75">
                                                    <span class="font-weight-bolder font-size-lg">Direct Sales</span>
                                                    <span class="font-weight-bolder font-size-h5">
                                                    Limited Co-Op</span>
                                                </div>
                                            </div>
                                            <!--end::Item-->
                                            <!--begin::Item-->
                                            <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                                                <span class="mr-4">
                                                    <i class="flaticon-pie-chart display-4 text-muted font-weight-bold"></i>
                                                </span>
                                                <div class="d-flex flex-column text-dark-75">
                                                    <span class="font-weight-bolder font-size-lg"># of Sellers</span>
                                                    <span class="font-weight-bolder font-size-h5">
                                                    2,300</span>
                                                </div>
                                            </div>
                                            <!--end::Item-->
                                            <!--begin::Item-->
                                            <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                                                <span class="mr-4">
                                                    <i class="flaticon-file-2 display-4 text-muted font-weight-bold"></i>
                                                </span>
                                                <div class="d-flex flex-column flex-lg-fill">
                                                    <span class="font-weight-bolder font-size-lg" >MAP Policy</span>
                                                    <a href="#" class="text-primary font-weight-bolder">Yes</a>
                                                </div>
                                            </div>
                                            <!--end::Item-->
                                            <!--begin::Item-->
                                            <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                                                <span class="mr-4">
                                                    <i class="flaticon-chat-1 display-4 text-muted font-weight-bold"></i>
                                                </span>
                                                <div class="d-flex flex-column">
                                                    <span class="text-dark-75 font-weight-bolder font-size-lg">648 Reviews</span>
                                                    <a href="#" class="text-primary font-weight-bolder">View</a>
                                                </div>
                                            </div>
                                            <!--end::Item-->
                                            <!--begin::Item-->
                                            <div class="d-flex align-items-center flex-lg-fill mb-2 float-left">



                                            </div>
                                            <!--end::Item-->
                                        </div>
                                        <!--begin::Items-->
                                    </div>
                                </div>
                                <!--end::Card-->
                                <!--begin::Row-->
                                <div class="row">
                                    <div class="col-lg-8">
                                        <!--begin::Advance Table Widget 2-->
                                        <div class="card card-custom card-stretch gutter-b shadow">
                                            <!--begin::Header-->
                                            <div class="card-header border-0 pt-5">
                                                <h3 class="card-title align-items-start flex-column">
                                                    <span class="card-label font-weight-bolder text-dark">Featured Products</span>
                                                    <span class="text-muted mt-3 font-weight-bold font-size-sm">View all Items </span>
                                                </h3>

                                            </div>
                                            <!--end::Header-->
                                            <!--begin::Body-->
                                            <div class="card-body pt-3 pb-0">
                                                <!--begin::Table-->

                                                  <ul class="list-group ">
                <!-- list group item-->
                <li class="list-group-item">
                    <!-- Custom content-->
                    <div class="media align-items-lg-center flex-column flex-lg-row p-3">
                        <div class="media-body order-2 order-lg-1">
                            <h5 class="mt-0 font-weight-bold mb-2">Mountain Bike 25 blue</h5>
                            <p class="font-italic mb-0 ">Big wheels and black finish</p>
                            <div class="d-flex align-items-center justify-content-between mt-1">
                                <h6 class="font-weight-bold my-2">Case Pack - 6</h6>
                                <ul class="list-inline small">
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star-o text-gray"></i></li>
                                </ul>
                            </div>
                        </div><img src="media/bike1.jpg" alt="Generic placeholder image" width="200" class="ml-lg-5 order-1 order-lg-2">
                    </div> <!-- End -->
                </li> <!-- End -->
                <!-- list group item-->
                <li class="list-group-item">
                    <!-- Custom content-->
                    <div class="media align-items-lg-center flex-column flex-lg-row p-3">
                        <div class="media-body order-2 order-lg-1">
                            <h5 class="mt-0 font-weight-bold mb-2">Sant Cruz Mountain Bike</h5>
                            <p class="font-italic  mb-0 ">Big Wheel Disc Brake with lighting fast response</p>
                            <div class="d-flex align-items-center justify-content-between mt-1">
                                <h6 class="font-weight-bold my-2">Case Pack - 6</h6>
                                <ul class="list-inline small">
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                </ul>
                            </div>
                        </div><img src="media/bike2.jpg" alt="Generic placeholder image" width="200" class="ml-lg-5 order-1 order-lg-2">
                    </div> <!-- End -->
                </li> <!-- End -->
                <!-- list group item -->
                <li class="list-group-item">
                    <!-- Custom content-->
                    <div class="media align-items-lg-center flex-column flex-lg-row p-3">
                        <div class="media-body order-2 order-lg-1">
                            <h5 class="mt-0 font-weight-bold mb-2">Small bike for Kids</h5>
                            <p class="font-italic  mb-0 ">Bionic motor for fast uphill times and to not get super tired.</p>
                            <div class="d-flex align-items-center justify-content-between mt-1">
                                <h6 class="font-weight-bold my-2">Case Pack - 6</h6>
                                <ul class="list-inline small">
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star-o text-gray"></i></li>
                                </ul>
                            </div>
                        </div><img src="media/bike1.jpg" alt="Generic placeholder image" width="200" class="ml-lg-5 order-1 order-lg-2">
                    </div> <!-- End -->
                </li> <!-- End -->
                <!-- list group item -->
                <li class="list-group-item">
                    <!-- Custom content-->
                    <div class="media align-items-lg-center flex-column flex-lg-row p-3">
                        <div class="media-body order-2 order-lg-1">
                            <h5 class="mt-0 font-weight-bold mb-2">Mountain Bike Gecko 3 27"</h5>
                            <p class="font-italic  mb-0 ">12 speed disc brakes  Black Green</p>
                            <div class="d-flex align-items-center justify-content-between mt-1">
                                <h6 class="font-weight-bold my-2">Case Pack - 6</h6>
                                <ul class="list-inline small">
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                    <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                                </ul>
                            </div>
                        </div><img src="media/bike1.jpg" alt="Generic placeholder image" width="200" class="ml-lg-5 order-1 order-lg-2">
                    </div> <!-- End -->
                </li> <!-- End -->
            </ul> <!-- End -->

                                                <!--end::Table-->
                                            </div>
                                            <!--end::Body-->
                                        </div>
                                        <!--end::Advance Table Widget 2-->
                                    </div>
                                    <div class="col-lg-4">
                                        <!--begin::Mixed Widget 14-->
                                        <div class="card card-custom  gutter-b">
                                            <!--begin::Header-->
                                            <div class="card-header border-0 pt-5">
                                                <h3 class="card-title font-weight-bolder">Our Brands</h3>

                                            </div>
                                            <!--end::Header-->
                                            <!--begin::Body-->
                                            <div class="card-body p-0 position-relative overflow-hidden">


                                <div class="row m-0">
                                                        <div class="col  px-6 py-8 rounded-xl mr-7 mb-7">
                                                                <div class=" ">
   <img class="img-fluid" src="media/bikelogo1.png" alt="image">
               </div>
                                                        </div>
                                                        <div class="col  px-6 py-8 rounded-xl mr-7 mb-7">
                                                            <div class=" float-right">
                                                               <img class="img-fluid" src="media/bikelogo2.png" alt="image">
                                                            </div>

                                                        </div>
                                                    </div>

                                        <div class="pt-5">

                                             <div class="row m-0">
                                                        <div class="col  px-6 py-8 rounded-xl mr-7 mb-7">
                                                                <div class=" ">
                                                                   <img class="img-fluid" src="media/bikelogo3.png" alt="image">
                                                                </div>
                                                        </div>
                                                        <div class="col  px-6 py-8 rounded-xl mr-7 mb-7">
                                                            <div class=" float-right">
                                                               <img class="img-fluid" src="media/bikelogo4.png" alt="image">
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <!--end::Body-->
                                        </div>
                                        <!--end::Mixed Widget 14-->


                                        <!--begin::Mixed Widget 14-->
                                        <div class="card card-custom gutter-b">
                                            <!--begin::Header-->
                                            <div class="card-header border-0 pt-5">
                                                <h3 class="card-title font-weight-bolder">Dealer Requirements</h3>

                                            </div>
                                            <!--end::Header-->
                                            <!--begin::Body-->
                                            <div class="card-body p-0 position-relative overflow-hidden">




                                        <div class="pt-5">
                                          <div class=" align-items-lg-center flex-column flex-lg-row p-3">The exact ways that dealer licenses are called and defined differs from state to state. For example, new car dealers in one state may also be authorized to sell used vehicles, whereas they may not be allowed the same in another. Yet, regardless of these minor differences, there are several major dealer license types that are found in all states</div>

                                                </div>
                                            </div>
                                            <!--end::Body-->
                                        </div>
                                        <!--end::Mixed Widget 14-->

                                    </div>
                                </div>
                                <!--end::Row-->
                                <!--begin::Row-->
                                <!--end::Row-->
                            </div>
                            </div>





  </div>
</template>

<script>
var moment = require("moment");
import gql from "graphql-tag";


export default {
  data() {
    return {
      company: {},
      moment: moment,
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id
    };
  },
  apollo: {
    company: {
      query: gql`
        query Companies($id: ID!) {
          company(id: $id) {
            id
            company_name
email
            company_description
city
state
shortdescription
            image {
              url
            }
            published_at
          }
        }
      `,
      variables() {
        return {
          id: this.routeParam
        };
      }
    }
  }
};
</script>

