import ApiService from '@/core/services/api.service'
import JwtService from '@/core/services/jwt.service'
import { matrixClient } from '@/core/services/matrix'

// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const FORGOT_PASSWORD = 'forgotpassword'
export const RESET_PASSWORD = 'resetpassword'
export const UPDATE_USER = 'updateUser'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setUser'
export const SET_ERROR = 'setError'

const state = {
  errors: [],
  user: {},
  token: {},
  isAuthenticated: !!JwtService.getToken(),
}

const getters = {
  currentUser(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
}

const actions = {
  async [LOGIN](context, credentials) {
    try {
      const { data } = await ApiService.post('auth/local/', credentials)
      context.commit(SET_AUTH, data)
      await context.dispatch(VERIFY_AUTH)
      return data
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.data
      ) {
        context.commit(SET_ERROR, error.response.data.data[0].messages)
      } else {
        context.commit(SET_ERROR, {
          id: 'unknown error',
          message: 'Login error. Please try again',
        })
      }
    }
  },
  async [LOGOUT](context) {
    if (context.rootState.matrix.matrixAuth) {
      try {
        matrixClient.stopClient()
        context.commit('REMOVE_MATRIX_AUTH')
        localStorage.removeItem('matrixAuth')
        await matrixClient.logout()
      } catch (error) {
        // console.log(error);
      }
    }

    context.commit(PURGE_AUTH)
  },
  [REGISTER](context, credentials) {
    return ApiService.post('users', credentials)
  },
  [FORGOT_PASSWORD](context, payload) {
    return ApiService.post('auth/forgot-password/', payload)
  },
  [RESET_PASSWORD](context, payload) {
    return new Promise((resolve) => {
      ApiService.post('auth/reset-password/', payload)
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.data[0].messages)
        })
    })
  },
  async [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      // if already auth and have data, then don't do anything
      if (context.state.user.id) {
        return
      }
      ApiService.setHeader()
      try {
        const { data } = await ApiService.get('users/me')
        await context.dispatch('matrixAuth', data)
        context.commit('setUserData', data)
      } catch (error) {
        context.commit(SET_ERROR, error.response.data[0].messages)
      }
    } else {
      context.commit(PURGE_AUTH)
    }
  },

  [UPDATE_USER](context, payload) {
    const {
      email,
      password,
      contact_email,
      contact_phone,
      business_title,
      show_contact_email,
      show_contact_phone,
      show_business_title,
      get_notice,
      expert,
    } = payload
    const user = {
      email,
      contact_email,
      contact_phone,
      business_title,
      show_contact_email,
      show_contact_phone,
      show_business_title,
      get_notice,
      expert,
    }
    if (password) {
      user.password = password
    }
    return ApiService.put('users/' + state.user.id, user).then(() => {
      ApiService.get('users/me').then(({ data }) => {
        context.commit('setUserData', data)
      })
    })
  },
  getUserInfo: () => {
    if (JwtService.getToken()) {
      ApiService.setHeader()
      return ApiService.get('users/me')
    } else {
      return false
    }
  },
  getUserFromEmail(context, payload) {
    return ApiService.get('users', '?_where[0][email]=' + payload.email)
  },
  updateUserInfo(context, payload) {
    return ApiService.put('users/' + state.user.id, payload)
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true
    state.errors = {}
    state.token = { token: user.jwt }
    JwtService.saveToken(user.jwt)
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    JwtService.destroyToken()
    ApiService.destroyHeader()
    window.localStorage.removeItem('id_role')
  },
  setUserData(state, payload) {
    state.user = payload
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
