import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import "@/sass/overrides.sass";
import "@/sass/headers.scss";
import { ClickOutside } from "vuetify/lib/directives/click-outside";

Vue.use(Vuetify, { directives: { ClickOutside } });

const theme = {
  primary: "#97b7be",
  secondary: "#EEA47F",
  accent: "#9C27b0",
  info: "#00CAE3"
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
    
      light: theme
    }
  }
});
