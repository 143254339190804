import sdk from "matrix-js-sdk";
const baseUrl = "https://matrix." + process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN;

var matrixClient = sdk.createClient(baseUrl);

function updateClient(auth) {
  matrixClient = sdk.createClient({
    baseUrl,
    accessToken: auth.access_token,
    userId: auth.user_id,
    deviceId: auth.device_id,
  });
}

export { matrixClient, updateClient };
