import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from './router'
import store from '@/core/services/store'
import { LOGOUT } from './core/services/store/auth.module'

Vue.use(AclInstaller)

// const timeout = ms => new Promise(resolve => setTimeout(resolve, ms))
let initialRole = window.localStorage.getItem('id_role')
if (!initialRole) {
  initialRole = 'public'
}
export default new AclCreate({
  initial: initialRole,
  notfound: {
    path: '/',
    forwardQueryParams: true,
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    isAuthenticated: new AclRule('authenticated').generate(),
    isPublic: new AclRule('public')
      .or('supplier')
      .or('authenticated')
      .or('seller')
      .or('admin')
      .or('notapproved')
      .generate(),
    isSupplier: new AclRule('supplier').generate(),
    isSeller: new AclRule('seller').generate(),
    isBuySell: new AclRule('supplier').or('seller').generate(),
    isNotapproved: new AclRule('notapproved').generate(),
    isRegistered: new AclRule('supplier')
      .or('authenticated')
      .or('admin')
      .or('seller')
      .generate(),
    isAdmin: new AclRule('admin').generate(),
  },
  middleware: async (acl) => {
    const token = store.state.auth.token.token
    if (token) {
      // await store.dispatch(LOGOUT)
      var base64Url = token.split('.')[1]
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
      const tokenDecode = JSON.parse(jsonPayload)
      const expirationToken = tokenDecode.exp * 1000
      const date = new Date()
      const currentDate = Date.parse(date)
      if (currentDate >= expirationToken) {
        await store.dispatch(LOGOUT)
        location.reload()
      }
    }
    try {
      // TODO add a try catch to this to prevent reloading constantly.
      const data = await store.dispatch('getUserInfo')
      if (data.data != undefined) {
        window.localStorage.setItem('id_role', data.data.role.type)
        if (
          (data.data.role.type == 'supplier' ||
            data.data.role.type == 'seller' ||
            data.data.role.type == 'admin') &&
          (data.data.approved == false || data.data.approved == null)
        ) {
          acl.change('notapproved')
        } else {
          acl.change(data.data.role.type)
        }
      }
    } catch (error) {
      await store.dispatch(LOGOUT)
      location.reload()
    }
  },
})
