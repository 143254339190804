import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Lock',
          path: 'lock',
          component: () => import('@/views/pages/Lock'),
        },

        {
          name: 'reset',
          path: '/reset',
          meta: {
            rule: 'isPublic',
          },
          component: () => import('@/view/pages/auth/ResetPassword'),
        },

        {
          name: 'Login',
          path: 'login',
          meta: {
            rule: 'isPublic',
          },
          component: () => import('@/views/pages/Login'),
        },
        {
          name: 'Login Seller',
          path: 'loginseller',
          meta: {
            rule: 'isPublic',
          },
          component: () => import('@/views/pages/LoginSeller'),
        },
        {
          name: 'Pricing',
          meta: {
            rule: 'isPublic',
          },
          path: 'pricing',
          component: () => import('@/views/pages/Pricing'),
        },
        {
          name: 'Register for Goose',
          path: 'register',
          meta: {
            rule: 'isPublic',
          },
          component: () => import('@/views/pages/Register'),
        },

        {
          name: 'forgot password',
          path: 'forgot',
          meta: {
            rule: 'isPublic',
          },
          component: () => import('@/view/pages/auth/ForgotPassword'),
        },
      ],
    },
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/views/dashboard/Index'),

      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '/dashboard',
          meta: {
            rule: 'isPublic',
          },
          component: () => import('@/views/dashboard/Dashboard'),
        },

        {
          path: '/account',
          name: 'Account',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/account/Account.vue'),
        },

        {
          path: '/seller/dealers',
          name: 'dealers',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/seller/Dealers.vue'),
        },

        /* Profile INFO
         */

        {
          path: '/profile',
          name: 'profile',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/Profile.vue'),
        },
        {
          path: '/seller/profile-editor',
          name: 'Business Profile Editor',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/seller/Profile-editor.vue'),
        },


        {
          path: '/seller/widget',
          name: 'widget',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/seller/Widget.vue'),
        },


        // {
        //   path: '/buyer/profile-editor',
        //   name: 'Retailer Profile Editor',
        //   meta: {
        //     rule: 'isBuySell',
        //   },
        //   component: () =>
        //     import('@/view/pages/buyer/Buyer-profile-editor.vue'),
        // },

        {
          path: '/seller/profile/:id',
          name: 'supplier own detail',
          meta: {
            rule: 'isSupplier',
          },
          component: () =>
            import('@/view/pages/seller/Supplier-detail-own.vue'),
        },
        {
          path: '/seller/supplier-detail/:id',
          name: 'Supplier Profile',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/seller/Supplier-detail.vue'),
        },

        /*         Applications
         */

        {
          path: '/crm/applications',
          name: 'Applications List',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/seller/Applications.vue'),
        },

        // {
        //   path: '/buyer/applications',
        //   name: 'My Applications',
        //   meta: {
        //     rule: 'isBuySell',
        //   },
        //   component: () => import('@/view/pages/buyer/Myapplications.vue'),
        // },

        // {
        //   path: '/buyer/mypartners',
        //   name: 'My Partner List',
        //   meta: {
        //     rule: 'isBuySell',
        //   },
        //   component: () => import('@/view/pages/buyer/Mysuppliers.vue'),
        // },
        {
          path: '/crm/applications/detail/:id',
          name: 'Application Detail',
          meta: {
            rule: 'isSupplier',
          },
          component: () => import('@/view/pages/seller/Application-detail.vue'),
        },
        // {
        //   path: '/buyer/applicationsdetail/:id',
        //   name: 'Buyer Application Detail',
        //   meta: {
        //     rule: 'isSeller',
        //   },
        //   component: () => import('@/view/pages/buyer/Application-detail.vue'),
        // },
        {
          path: '/crm/applicationbuilder',
          name: 'Application Builder',
          meta: {
            rule: 'isBuySell',
          },
          component: () =>
            import('@/view/pages/seller/Application-builder.vue'),
        },

        {
          path: '/pim/pricing',
          name: 'Pricing Lists',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/crm/PricingLists.vue'),
        },
        {
          path: '/pim/stock',
          name: 'Stock Management',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Stock.vue'),
        },
        {
          path: '/pim/price-detail/:id',
          name: 'Pricing Lists detail',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/crm/PricingDetail.vue'),
        },

        {
          path: '/seller/applicationanswer/:id',
          name: 'Application Answer',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/seller/Application-answer.vue'),
        },

        /* STORE FUNCTIONS
         */

        {
          path: '/market/order-list',
          name: 'Orders List',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-seller/Orders-list.vue'),
        },
        {
          path: '/market/invoice-list',
          name: 'Invoices List',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-seller/Invoice-list.vue'),
        },
        {
          path: '/market/shipment-list',
          name: 'Shipments List',
          meta: {
            rule: 'isBuySell',
          },
          component: () =>
            import('@/view/pages/store-seller/Shipment-list.vue'),
        },
        {
          path: '/market/payouts',
          name: 'Payouts',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-seller/Payouts.vue'),
        },
        {
          path: '/market/create-invoice/:id?',
          name: 'Invoice Create',
          meta: {
            rule: 'isBuySell',
          },
          component: () =>
            import('@/view/pages/store-seller/InvoiceCreate.vue'),
        },
        {
          path: '/market/ship-order/:id?',
          name: 'Ship Order',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-seller/ShipOrder.vue'),
        },
        {
          path: '/market/return-order/:id?',
          name: 'Return Order',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-seller/ReturnOrder.vue'),
        },
        {
          path: '/market/invoice-detail/:id?',
          name: 'Invoice Detail market',
          meta: {
            rule: 'isBuySell',
          },
          component: () =>
            import('@/view/pages/store-seller/InvoiceDetail.vue'),
        },
        {
          path: '/market/return-detail/:id?',
          name: 'Return detail',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-seller/ReturnDetail.vue'),
        },
        {
          path: '/market/ship-detail/:id?',
          name: 'Ship Detail market',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-seller/ShipDetail.vue'),
        },

        {
          path: '/store/vendor-order-tracking/:id?',
          name: 'Shipment List',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-buyer/OrderTracking.vue'),
        },
        {
          path: '/store/vendor-order-invoice/:id?',
          name: 'Invoice List',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-buyer/OrderInvoice.vue'),
        },
        {
          path: '/store/orders',
          name: 'Order List',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-buyer/OrderList.vue'),
        },

        

        {
          path: '/store/orderprint',
          name: 'Order print',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-buyer/OrderPrint.vue'),
        },

        {
          path: '/store/order-detail/:id?',
          name: 'Order detail',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-buyer/OrderDetail.vue'),
        },
        {
          path: '/store/order-return-list/:id?',
          name: 'Order Return List',
          meta: {
            rule: 'isBuySell',
          },
          component: () =>
            import('@/view/pages/store-buyer/OrderReturnList.vue'),
        },
        {
          path: '/store/invoice-detail/:id?',
          name: 'Invoice Detail',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-buyer/InvoiceDetail.vue'),
        },
        {
          path: '/store/ship-detail/:id?',
          name: 'Ship Detail',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-buyer/ShipDetail.vue'),
        },
        {
          path: '/store/return-detail/:id?',
          name: 'Order Returns List',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-buyer/ReturnDetail.vue'),
        },

        {
          path: '/store/return',
          name: 'return demo',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-seller/Return.vue'),
        },

        {
          path: '/store/order-return/:id?',
          name: 'Order Return',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-buyer/OrderReturn.vue'),
        },

        {
          path: '/store/invoices',
          name: 'Invoice Lists',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-buyer/Invoices.vue'),
        },

        {
          path: '/store/shipments',
          name: 'Shipment List Store',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-buyer/Shipments.vue'),
        },

        {
          path: '/market/order-detail/:id?',
          name: 'Order Detail',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/store-seller/Comm-order.vue'),
        },

        {
          path: '/market/seller-settings',
          name: 'Seller Edit Settings',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/market/SupplierSettings.vue'),
        },



        {
          path: '/billing-center',
          name: 'Billing Center Supplier',
          meta: {
            rule: 'isSupplier',
          },
          component: () => import('@/view/pages/store-seller/Billing.vue'),
        },
        {
          path: '/billing-detail',
          name: 'Billing Detail',
          meta: {
            rule: 'isSupplier',
          },
          component: () => import('@/view/pages/store-seller/Payment.vue'),
        },

        /* PIM FUNCTIONS
         */

        {
          path: '/pim/assortments',
          name: 'PIM product assortments',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Assortment.vue'),
        },

        {
          path: '/pim/assortment-view/:id',
          name: 'PIM product categories view',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Assortment-detail.vue'),
        },

        {
          path: '/pim/list',
          name: 'PIM product lists',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Product-cards.vue'),
        },

        {
          path: '/pim/channels',
          name: 'PIM product Channels',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Channels.vue'),
        },
        {
          path: '/pim/channels/edit/:id',
          name: 'PIM product Channels Edit',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Channel-edit.vue'),
        },

        {
          path: '/pim/channels/detail/:id',
          name: 'PIM product Channels detail',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Channel-detail.vue'),
        },
        {
          path: '/pim/channels/new',
          name: 'PIM product Channels New',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Channel-new.vue'),
        },

        {
          path: '/pim/item-new',
          name: 'PIM product Items New',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Item-new.vue'),
        },
        {
          path: '/pim/item-edit/:id',
          name: 'PIM product Items Edit',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Item-edit.vue'),
        },
        {
          path: '/pim/item-view/:id',
          name: 'PIM product Items View',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Item-view.vue'),
        },

        {
          path: '/pim/export',
          name: 'PIM product Items export',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Export.vue'),
        },

        {
          path: '/pim/import',
          name: 'PIM product Items Import',
          meta: {
            rule: 'isSupplier',
          },
          component: () => import('@/view/pages/pim/Import.vue'),
        },

        {
          path: '/pim/brands',
          name: 'PIM product brands',
          meta: {
            rule: 'isSupplier',
          },
          component: () => import('@/view/pages/pim/Brands.vue'),
        },

        {
          path: '/pim/products/:id',
          name: 'PIM product Supplier',
          meta: {
            rule: 'isSeller',
          },
          component: () => import('@/view/pages/pim/Products.vue'),
        },

        {
          path: '/pim/product/new',
          name: 'New PIM product',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Product-new.vue'),
        },
        {
          path: '/pim/product-detail/:id',
          name: 'PIM product detail',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Product-detail.vue'),
        },
        {
          path: '/pim/product-view/:id',
          name: 'PIM product view',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/pim/Product-view.vue'),
        },

        /* Seller Messaging
         */
        {
          path: '/seller/messaging',
          meta: {
            rule: 'isBuySell',
          },
          component: () =>
            import('@/view/pages/messaging/Seller-messaging.vue'),
        },

        {
          path: '/seller/messaging/thread/:id',
          name: 'Seller Main Messaging',
          meta: {
            rule: 'isBuySell',
          },
          component: () =>
            import('@/view/pages/messaging/Seller-messaging.vue'),
        },

        /* Supplier Messaging
         */
        {
          path: '/supplier/messaging',
          meta: {
            rule: 'isBuySell',
          },
          component: () =>
            import('@/view/pages/messaging/Supplier-messaging.vue'),
        },

        {
          path: '/supplier/messaging/thread/:id',
          name: 'Supplier Main Messaging',
          meta: {
            rule: 'isBuySell',
          },
          component: () =>
            import('@/view/pages/messaging/Supplier-messaging.vue'),
        },
        /* Admin FUNCTIONS
         */

        {
          path: '/admin/adduser',
          name: 'Add User Admin',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/admin/User-add.vue'),
        },

        /* CRM FUNCTIONS
         */

        // invite-seller
        {
          path: '/crm/invite-seller',
          name: 'Invite Seller',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/crm/Invite-seller.vue'),
        },
        {
          path: '/crm/partners',
          name: 'CRM Partner List',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/crm/Partner-list.vue'),
        },

        {
          path: '/crm/partner-detail/:id',
          name: 'CRM Partner Detail',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/crm/Partner-detail.vue'),
        },

        {
          path: '/crm/partner-edit/:id',
          name: 'CRM Partner Editor',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/crm/Partner-editor.vue'),
        },



        {
          path: '/advertise',
          name: 'Advertise',
          meta: {
            rule: 'isBuySell',
          },
          component: () => import('@/view/pages/seller/Advertise.vue'),
        },

        {
          path: '/company/:id',
          name: 'Company-Detail',
          meta: {
            rule: 'isBuySell',
          },
          components: require('@/view/pages/seller/Company.vue'),
        },

        // Pages

        // Root level
      ],
    },

    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          meta: {
            rule: 'isPublic',
          },
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})
